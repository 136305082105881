/* eslint-disable camelcase */
import { css } from '@emotion/core';

import { getBorderColors, getVocabColors } from '@client/utils/styles';

export const $root = (toggleLeveledText) => ({ typography, colors }) => {
  const vocabLevelQ1 = getVocabColors(1, colors);
  const vocabLevelQ2 = getVocabColors(2, colors);
  const vocabLevelQ3 = getVocabColors(3, colors);
  const vocabLevelQ4 = getVocabColors(4, colors);

  return [
    typography.family.circular,
    typography.size.M,
    css`
      display: block;
      position: relative;
      width: 100%;

      .lexileContainer {
        position: relative;
      }

      .lexileScore {
        position: absolute;
        left: ${toggleLeveledText ? '-90px' : '-75px'};
        width: 50px;
        text-align: right;
        color: ${colors.ui.grey[500]};
        font-size: 14px;
      }

      .lexileScore::selection {
        color: none;
        background: none;
      }

      .suggestedPowerWord {
        background: ${colors.ui.greyLight[50]};
        border-bottom: 1px dashed ${colors.ui.grey[500]}
      }

      .ProseMirror[data-placeholder]::before {
        color: grey;
        position: absolute;
        content: attr(data-placeholder);
        pointer-events: none;
      }

      /* Inline vocab styling, separated into bands (grey, blue, green, purple). */
      .vocabLevel {
        background: ${vocabLevelQ1.bg};
        border-bottom: 1px solid ${vocabLevelQ1.bgActive};
        position: relative;

        &:after {
          color: ${vocabLevelQ1.textActive};
          background-color: ${vocabLevelQ1.bgActive};
          text-align: center;
          top: 100%;
          left: 0;
          position: absolute;
          font-size: 12px;
          line-height: 1;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 6px;
          content: attr(vocab-level);
        }
      }

      .vocabLevelQ1 {
        background: ${vocabLevelQ1.bg};
        border-bottom: 2px solid ${vocabLevelQ1.bgActive};

        &:after {
          color: ${vocabLevelQ1.textActive};
          background-color: ${vocabLevelQ1.bgActive};
        }
      }

      .vocabLevelQ2 {
        background: ${vocabLevelQ2.bg};
        border-bottom: 2px solid ${vocabLevelQ2.bgActive};

        &:after {
          color: ${vocabLevelQ2.textActive};
          background-color: ${vocabLevelQ2.bgActive};
        }
      }

      .vocabLevelQ3 {
        background: ${vocabLevelQ3.bg};
        border-bottom: 2px solid ${vocabLevelQ3.bgActive};

        &:after {
          color: ${vocabLevelQ3.textActive};
          background-color: ${vocabLevelQ3.bgActive};
        }
      }

      .vocabLevelQ4 {
        background: ${vocabLevelQ4.bg};
        border-bottom: 2px solid ${vocabLevelQ4.bgActive};

        &:after {
          color: ${vocabLevelQ4.textActive};
          background-color: ${vocabLevelQ4.bgActive};
        }
      }
    `
  ];
};

// If the prosemirror input is configured with isMultiline: true
// we assume it can increase its height past the height of the viewport,
// so make the formatting menu sticky.
export const $menuWrapper = (isSticky, customStick) => ({ colors }) => {
  const base = css`
    align-items: flex-start;
    background-color: ${colors.ui.white[500]};
    display: flex;
    flex-flow: row wrap;
  `;

  const relative = css`
    position: relative;
    width: 100%;
  `;

  const sticky = css`
    position: sticky;
    top: ${customStick ? '108px' : '0px'};
    width: 100%;
    z-index: 1; /* overlay any highlighting in the text field */
    border: solid 1px #c8d1e0;
    border-radius: 8px;
    border-top: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `;

  return isSticky ? [base, sticky] : [base, relative];
};

export const $lexileInViewWrapper = (isSticky) => ({ colors }) => {
  const base = css`
    width: 100%;
    border: 1px solid ${colors.ui.greyLight[100]};
  `;

  const relative = css`
    position: relative;
    width: 100%;
  `;

  const sticky = css`
    position: sticky;
    bottom: 0;
    width: 100%;
  `;

  return isSticky ? [base, sticky] : [base, relative];
};

// Descendant styles target markup produced by an external library component, Popover.
export const $inputWrapper = css`
  width: 100%;

  & > span {
    width: 100%;

    & > span {
      width: 100%;
    }
  }
`;

export const $input = ({
  isMultiline,
  showVocabLevel,
  button,
  contextTab,
  isReadOnly,
  isDisabled,
  placeholder
}, variant) => (theme) => {
  const {
    typography,
    backgroundColor,
    input_backgroundColor,
    input_backgroundColor_disabled,
    color,
    colors,
    color_readOnly,
    input_color_placeholder
  } = theme;
  const [
    borderColor,
    borderColor_hover,
    borderColor_active
  ] = getBorderColors(theme, variant);

  return [
    typography.family.circular,
    typography.size.M,
    css`
    margin-top: ${contextTab ? '-1px' : '0'};

    & > div.ProseMirror {
      background-color:
        ${isReadOnly || isDisabled
        ? input_backgroundColor_disabled
        : input_backgroundColor};
      border: 1px solid ${borderColor};
      border-bottom: none;
      border-radius: ${contextTab ? '3px 0 3px 3px' : '3px'};
      color: ${isReadOnly ? color_readOnly : color};
      min-height: ${isMultiline ? '100px' : '40px'};
      outline: none;
      padding: ${button ? '9px 30px 9px 16px' : '9px 16px'};
      width: 100%;
      white-space: pre-wrap;
      line-height: ${showVocabLevel ? '2.5rem' : 'initial'};
      padding-bottom: 64px;
    }

    & > div.ProseMirror::after {
      content: "";
      clear: both;
      display: table;
    }

    & > div.ProseMirror * {
      margin: 0;
    }

    & > div.ProseMirror svg {
      margin: auto;
    }

    & > div.ProseMirror h2,
    & > div.ProseMirror h3 {
      margin-bottom: 16px;
    }

    & > div.ProseMirror blockquote {
      border-left: 3px solid ${borderColor};
      padding: 0 0 0 24px;
    }

    & > div.ProseMirror pre {
      background: ${input_backgroundColor_disabled};
      font-family: unset;
      font-size: unset;
    }

    & > div.ProseMirror hr {
      border: none;
      border-top: 3px solid ${borderColor};
      margin-left: 24px;
      width: calc(100% - 48px);
    }

    & > div.ProseMirror ul,
    & > div.ProseMirror ol {
      padding-left: 24px;
    }

    & > div.ProseMirror .power-word.power-word-defined {
      background-color: ${colors.ui.coreBlue[100]};
      border-bottom: 2px solid ${colors.ui.coreBlue[500]};
    }

    & > div.ProseMirror .power-word.power-word-undefined {
      background-color: ${colors.ui.red[100]};
      border-bottom: 2px dashed ${colors.ui.red[500]};
    }

    /* Add top margin to all top-level prosemirror blocks */
    & > div.ProseMirror > * {
      margin-top: 16px;
    }

    /* EXCEPT for the first paragraph and first lexile container */
    & > div.ProseMirror > p:first-of-type,
    & > div.ProseMirror > div:first-of-type {
      margin-top: 0;
    }
    
    & div.ProseMirror span[lang] {
      background-color: ${colors.brand.pink[50]};
      border-bottom: 2px solid ${colors.status.danger[100]};
      position: relative;
    }
  `,
    !isDisabled && css`
    & > div.ProseMirror:hover {
      border-color: ${borderColor_hover};
    }

    & > div.ProseMirror-focused {
      box-shadow: 0 0 0 1px ${backgroundColor}, 0 0 0 2px ${borderColor_active};
    }
  `,
    placeholder && css`
    & > div.ProseMirror > p.empty-node {
      position: relative;
    }

    & > div.ProseMirror > p.empty-node:before {
      color: ${input_color_placeholder};
      content: '${placeholder}';
      cursor: text;
      position: absolute;
    }
  `
  ];
};

export const $comparedTextInput = ({
  isMultiline,
  showVocabLevel,
  button,
  contextTab,
  isReadOnly,
  isDisabled,
  placeholder
}, variant) => (theme) => {
  const {
    typography,
    backgroundColor,
    input_backgroundColor_disabled,
    color,
    colors,
    color_readOnly,
    input_color_placeholder
  } = theme;
  const [
    borderColor,
    borderColor_hover,
    borderColor_active
  ] = getBorderColors(theme, variant);

  return [
    typography.family.circular,
    typography.size.M,
    css`
    margin-top: ${contextTab ? '-1px' : '0'};

    & > div.ProseMirror {
      background-color: #f7f9f9;
      border: 1px solid ${borderColor};
      border-radius: ${contextTab ? '3px 0 3px 3px' : '3px'};
      color: ${isReadOnly ? color_readOnly : color};
      min-height: ${isMultiline ? '100px' : '40px'};
      outline: none;
      padding: ${button ? '9px 30px 9px 16px' : '9px 16px'};
      width: 100%;
      white-space: pre-wrap;
      line-height: ${showVocabLevel ? '2.5rem' : 'initial'};
      padding-bottom: 64px;

    }

    & > div.ProseMirror::after {
      content: "";
      clear: both;
      display: table;
    }

    & > div.ProseMirror * {
      margin: 0;
    }

    & > div.ProseMirror svg {
      margin: auto;
    }

    & > div.ProseMirror h2,
    & > div.ProseMirror h3 {
      margin-bottom: 16px;
    }

    & > div.ProseMirror blockquote {
      border-left: 3px solid ${borderColor};
      padding: 0 0 0 24px;
    }

    & > div.ProseMirror pre {
      background: ${input_backgroundColor_disabled};
      font-family: unset;
      font-size: unset;
    }

    & > div.ProseMirror hr {
      border: none;
      border-top: 3px solid ${borderColor};
      margin-left: 24px;
      width: calc(100% - 48px);
    }

    & > div.ProseMirror ul,
    & > div.ProseMirror ol {
      padding-left: 24px;
    }

    & > div.ProseMirror .power-word.power-word-defined {
      background-color: ${colors.ui.coreBlue[100]};
      border-bottom: 2px solid ${colors.ui.coreBlue[500]};
    }

    & > div.ProseMirror .power-word.power-word-undefined {
      background-color: ${colors.ui.red[100]};
      border-bottom: 2px dashed ${colors.ui.red[500]};
    }

    /* Add top margin to all top-level prosemirror blocks */
    & > div.ProseMirror > * {
      margin-top: 16px;
    }

    /* EXCEPT for the first paragraph and first lexile container */
    & > div.ProseMirror > p:first-of-type,
    & > div.ProseMirror > div:first-of-type {
      margin-top: 0;
    }
    
    & div.ProseMirror span[lang] {
      background-color: ${colors.brand.pink[50]};
      border-bottom: 2px solid ${colors.status.danger[100]};
      position: relative;
    }
  `,
    !isDisabled && css`
    & > div.ProseMirror:hover {
      border-color: ${borderColor_hover};
    }

    & > div.ProseMirror-focused {
      box-shadow: 0 0 0 1px ${backgroundColor}, 0 0 0 2px ${borderColor_active};
    }
  `,
    placeholder && css`
    & > div.ProseMirror > p.empty-node {
      position: relative;
    }

    & > div.ProseMirror > p.empty-node:before {
      color: ${input_color_placeholder};
      content: '${placeholder}';
      cursor: text;
      position: absolute;
    }
  `
  ];
};

export const $attachedButton = css`
  position: absolute;
  right: 0;
  top: 15px;
`;

export const $dualViewContainer = css`
  display: flex;
  border: solid 1px #C0C5CF;
  border-bottom: none;

  & > :first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
    margin-right: 4px;
    & > div:first-of-type > div:first-of-type {
        border: solid 1px #767676;
            
      }
  }

  & > :nth-child(2) {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 16px 32px 4px;
    & > div:first-of-type {
        border: solid 1px #767676;
      }
  }
`;

export const $leveledTextContainer = (loading) => css`
  background-color: #f7f8f8;
  position: relative;
  width: 100%;
  ${loading ? `
    flex: 1;
    & > div:first-of-type {
      z-index: 0;
      min-height: 40px;  
  ` : `
    flex: none;
  `}
`;

export const $loadingStylesOverrides = 'padding-bottom: 24px;';
